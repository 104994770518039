import Vue from 'vue'
import { Form, Field, Toast } from 'vant'
import role from '../../assets/lib/role'
import { PHONE } from '../../utils/regExp'
import { login } from '../../api/loginApi'
import con from '../../utils/common'
import $ from 'jquery'
let Base64 = require('js-base64').Base64

Vue.use(Form)
Vue.use(Field)
Vue.use(Toast)

export default {
  components: {},
  data () {
    return {
      userPhone: '',
      userPassword: '',
      checked: true,
      maxLength: "11",
      code: '',
      flag: 0,
      openId: '',
      accessToken: ''
    }
  },
  created () {

    //this.getCode1()
  },
  methods: {
    onSubmit (values) {
      var flag = this.resVerification()
      if (flag) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0,
        })
        var data = JSON.stringify({
          userPassword: Base64.encode(this.userPassword),
          userPhone: this.userPhone,
          standby2: localStorage.getItem('openId')
        })
        login(data).then((res) => {
          Toast.clear()
          if (res.code == 200) {
            localStorage.setItem('token', res.token)
            Toast({
              message: '登录成功！',
              forbidClick: true,
              onClose: () => {
                this.$router.push({
                  name: 'Home'
                })
              },
            })
          } else {
            Toast(res.msg)
          }
        })
      }


    },
    register () {
      this.$router.push({ path: '/cregister' })
    },
    toAgreement () {
      this.$router.push({ name: 'Agreement' })
    },
    forgetPwd () {
      this.$router.push({
        path: '/findPwd', query: {
          types: 0
        }
      })
    },
    goWxz () {

      this.checked = false
    },
    goXz () {
      this.checked = true
    },
    resVerification: function () {

      if (this.userPhone == "") {
        Toast('手机号不能为空！')
        return false
      } else if (!role.phone.test(this.userPhone)) {
        Toast('手机号格式错误！')
        return false
      } else if (this.userPassword == "") {
        Toast('密码不能为空！')
        return false
      } else {
        return true
      }
    },
    // 授权登录
    /*getCode1 () { // 非静默授权，第一次有弹框
      var http='http://test.jk-cloud.com.cn/api/'
      const self = this
      const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      const local = window.location.href
      if (code == null || code == '' || code == undefined) {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + con.wxAppid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
        return
      } else {
        self.code = code
        var api1 = http + '/system/user/selectCode'
        $.ajax({
          type: 'post',
          url: api1,
          data: {
            code: self.code
          },
          success: function (res) {
            if (res.code == 200) {

              self.flag = res.flag
              self.openId = res.openid
              self.accessToken = res.accessToken
              if (res.flag != 1) {
                var api3 = http + '/system/user/accreditLogin'
                $.ajax({
                  type: 'post',
                  url: api3,
                  data: {
                    openId: res.openid,
                    flag: res.flag,
                    accessToken: res.accessToken
                  },
                  success: function (res) {
                    if (res.code == 200) {

                      window.location.href = aiwoyun + res.token
                    } else {
                      Toast(res.msg)
                    }

                  }
                })


              }
            } else {
              Toast(res.msg)

            }

          }
        })


      }
    },*/
    getUrlParam: function (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },

  },
}
