import { post2 } from '../utils/request';

/**
 * 登录接口
 * @param {*} params --请求参数
 */
// eslint-disable-next-line import/prefer-default-export
export async function login(params) {
  return post2('/system/user/login', params);
}
